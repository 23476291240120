import React from 'react'
import { FcCollaboration } from 'react-icons/fc'
import { FcReadingEbook } from 'react-icons/fc'

const About = () => {
  return (
    <section id='about' className='bg-lite'>
      <div className="s-100"></div>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <h2 className="title c-dark">About Us</h2>
            </div>
            <div className="col-lg-9">
              <p className='c-dark title-sub'>Currently, the number of disabled veterans, according to experts, reaches more than three million people. Unfortunately, the war continues and this figure is not final. Our social rehabilitation program is aimed, first of all, at returning them to an active, as independent and fulfilling life as possible.</p>
            </div>
          </div>
          <div className="s-40"></div>
          <div className="row">
            <div className="col-lg-6">
              <div className="tl">
                <FcCollaboration size={80} />
                <div className="s-20"></div>
                <p className='c-dark title-sub'>The program was developed based on an integrated approach and the best experience of international experts. Over the course of a month, program participants receive: psychological support, tutoring in basic IT/Digital skills and assistance in employment. In addition, we provide trainings for the visually impaired in using smartphones with voice access.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tl">
                <FcReadingEbook size={80} />
                <div className="s-20"></div>
                <p className='c-dark title-sub'>We help restore the quality of life for disabled veterans by teaching professions that can be done remote from home. Family members receive psychological support, theoretical knowledge and practical skills on the rules of accompanying disabled veterans, home improvement and legal advice.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="s-60"></div>
    </section>
  )
}

export { About }
