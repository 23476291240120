import React from 'react'
import logo from '../logo.svg'


const Footer = () => {

  return (
    <footer id="contact">
      <div>
        <div className="s-40"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-1 py-3">
              <img src={logo} alt='CF IT-SPROUT' width={48} height={48} />
            </div>
            <div className="col-lg-4 py-3">
              <a href="tel:+380503240484" className=' c-medium'>+380503240484</a><br />
              <a href="mailto:admin@it-sprout.org" className=' c-medium'>admin@it-sprout.org</a>
            </div>
            <div className="col-lg-7 tr py-3">
              <div className="s-10"></div>
              <a href='/' className='mx-3 c-medium'>Documents</a>
              <a href='https://secure.wayforpay.com/donate/itsprout' className='mx-3 c-medium'>Donate</a>
              <a href='/' className='mx-3 c-medium'>Linkedin</a>
            </div>
          </div>
        </div>
        <div className="s-40"></div>
      </div>
      <div className='bg-lite py-3'>
        <div className="container">
          <div className="s-20"></div>
          <div className="row">
            <div className="col-lg-6 py-3">
              <a href='/' className='c-medium title-mini'>© 2022 - CF IT-SPROUT. All rights reserved.</a>
            </div>
            <div className="col-lg-6 c-medium title-mini tr py-3">
              <a href="/cookie" className='mx-3'>Cookie policy</a>
              <a href="/privacy" className='mx-3'>Privacy policy</a>
            </div>
          </div>
          <div className="s-20"></div>
        </div>
      </div>
    </footer>
  );
};

export { Footer }
