import React from 'react'
import pic1 from '../../assets/pic1.jpg'
import pic2 from '../../assets/pic2.jpg'
import pic3 from '../../assets/pic3.jpg'
import pic4 from '../../assets/pic4.jpg'
import pic5 from '../../assets/pic5.jpg'
import pic from '../../assets/pic6.jpg'

const Results = () => {
  return (
    <section id='results'>
      <div className="s-100"></div>
      <div className="content">
        <div className="container">
          <h2 className="title c-dark">Our Results</h2>
          <div className="s-60"></div>
          <div className="row">
            <div className="col-lg-4">
              <img src={pic1} alt="" width={160} height={120} className='r-32' />
              <div className="s-10"></div>
              <p className="c-dark title-intro">20 +</p>
              <div className="s-10"></div>
              <p className="c-dark">Companies agreed to hire veterans</p>
            </div>
            <div className="col-lg-4">
              <img src={pic2} alt="" width={160} height={120} className='r-32' />
              <div className="s-10"></div>
              <p className="c-dark title-intro">300 +</p>
              <div className="s-10"></div>
              <p className="c-dark">People with disabilities are employed</p>
            </div>
            <div className="col-lg-4">
              <img src={pic3} alt="" width={160} height={120} className='r-32' />
              <div className="s-10"></div>
              <p className="c-dark title-intro">200 +</p>
              <div className="s-10"></div>
              <p className="c-dark">Women are protected from harassment</p>
            </div>
          </div>
          <div className="s-40"></div>
          <div className="row">
            <div className="col-lg-4">
              <div className="s-10"></div>
              <img src={pic4} alt="" width={160} height={120} className='r-32' />
              <div className="s-10"></div>
              <p className="c-dark title-intro">100 +</p>
              <div className="s-10"></div>
              <p className="c-dark">Blind veterans trained to use smartphones in voice mode</p>
            </div>
            <div className="col-lg-4">
              <div className="s-10"></div>
              <img src={pic5} alt="" width={160} height={120} className='r-32' />
              <div className="s-10"></div>
              <p className="c-dark title-intro">600 +</p>
              <div className="s-10"></div>
              <p className="c-dark">Veteran entrepreneurs with disabilities provide lead generation services</p>
            </div>
            <div className="col-lg-4">
              <div className="card-res">
                <p>Our recent aim is...</p>
                <img src={pic} alt="" width={160} height={120} className='r-32' />
                <div className="s-20"></div>
                <p className="c-dark">Advocating for inclusivity amidst war</p>
                <div className="s-5"></div>
                <a className='button outline' href="#donations">SWIFT</a>
              </div>
            </div>
          </div>
          <div className="s-80"></div>
          <div className="tc"><a href="/" className="button dark">Click to view a detailed report</a></div>
        </div>
      </div>
      <div className="s-100"></div>
    </section>
  )
}

export { Results }
