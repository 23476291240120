import React from 'react';
import ua from '../../assets/ua-flag.svg';

const Donations = () => {
  const handleButtonClick = () => {
    console.log('button');
  };

  return (
    <section id='donations' className='bg-lite'>
      <div className='s-100'></div>
      <div className='content'>
        <div className='container'>
          <div className='flex jcsb aic'>
            <h2 className='title c-dark'>Donation details</h2>
            <p className='c-dark pt-2'>
              <img src={ua} alt='Ukraine' width={48} height={32} />
              <span className='m-20'></span>
              &nbsp;&nbsp;&nbsp;Join in bringing Ukraine closer to victory!
            </p>
          </div>
          <div className='s-60'></div>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='card-res noactive'>
                <div className='flex jcsb'>
                  <h3 className='c-dark title-intro'>USD</h3>
                  <h4 className='c-dark title'>$</h4>
                </div>
                <div className='s-10'></div>
                <p className='c-dark'>Name of beneficiary:</p>
                <p className='c-dark'>
                  <strong>CF IT-Sprout</strong>
                </p>
                <div className='s-20'></div>
                <p className='c-dark'>IBAN Code:</p>
                <p className='c-dark'>
                  <strong>UA713052990000026009024919278</strong>
                </p>
                <div className='s-20'></div>
                <p className='c-dark'>Bank of beneficiary:</p>
                <p className='c-dark'>
                  <strong>JSC CB "PRIVATBANK"</strong>
                </p>
                <div className='s-20'></div>
                <p className='c-dark'>Bank SWIFT Code:</p>
                <p className='c-dark'>
                  <strong>PBANUA2X</strong>
                </p>
                <div className='s-20'></div>
                <p className='c-dark'>Purpose of payment:</p>
                <p className='c-dark'>
                  <strong>Charity</strong>
                </p>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='card-res noactive'>
                <div className='flex jcsb'>
                  <h3 className='c-dark title-intro'>EUR</h3>
                  <h4 className='c-dark title'>€</h4>
                </div>
                <div className='s-10'></div>
                <p className='c-dark'>Name of beneficiary:</p>
                <p className='c-dark'>
                  <strong>CF IT-Sprout</strong>
                </p>
                <div className='s-20'></div>
                <p className='c-dark'>IBAN Code:</p>
                <p className='c-dark'>
                  <strong>UA153052990000026009024914660</strong>
                </p>
                <div className='s-20'></div>
                <p className='c-dark'>Bank of beneficiary:</p>
                <p className='c-dark'>
                  <strong>JSC CB "PRIVATBANK"</strong>
                </p>
                <div className='s-20'></div>
                <p className='c-dark'>Bank SWIFT Code:</p>
                <p className='c-dark'>
                  <strong>PBANUA2X</strong>
                </p>
                <div className='s-20'></div>
                <p className='c-dark'>Purpose of payment:</p>
                <p className='c-dark'>
                  <strong>Charity</strong>
                </p>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='card-res noactive'>
                <div className='flex jcsb'>
                  <h3 className='c-dark title-intro'>UAH</h3>
                  <div className='s-10'></div>
                  <h4 className='c-dark title'>₴</h4>
                </div>
                <p className='c-dark'>Назва бенефіціара:</p>
                <p className='c-dark'>
                  <strong>БО БФ ІТ-ПАРОСТОК</strong>
                </p>
                <div className='s-20'></div>
                <p className='c-dark'>Код IBAN:</p>
                <p className='c-dark'>
                  <strong>UA283052990000026009034917066</strong>
                </p>
                <div className='s-20'></div>
                <p className='c-dark'>Банк бенефіціара:</p>
                <p className='c-dark'>
                  <strong>АТ КБ «ПриватБанк»</strong>
                </p>
                <div className='s-20'></div>
                <p className='c-dark'>ЄДРПОУ:</p>
                <p className='c-dark'>
                  <strong>44550531</strong>
                </p>
                <div className='s-20'></div>
                <p className='c-dark'>Призначення платежу:</p>
                <p className='c-dark'>
                  <strong>Благодійна допомога</strong>
                </p>
              </div>
            </div>
          </div>
          <div className='s-80'></div>
          <div className='tc'>
            <a
              href='https://secure.wayforpay.com/donate/itsprout'
              className='button dark'
              onClick={handleButtonClick}
              target='blank'
            >
              Quick donation
            </a>
          </div>
        </div>
      </div>
      <div className='s-100'></div>
    </section>
  );
};

export { Donations };
