import React, { useState, useEffect } from 'react'
import logo from '../assets/logo-full.svg'

const Nav = () => {
  const [opened, setOpened] = useState(true)

  useEffect(() => {

    if ( window.innerWidth < 769 && !opened) {
      document.body.classList.add('menu-active')
    } else {
      document.body.classList.remove('menu-active')
    }
  }, [opened])

  const toggleMenu = () => {
   setOpened(!opened)
  }

  return (
    <header>
      <div className='container'>
        <nav>
          <h1 id='logo' className='flex aic'>
            <a href='/' aria-label='Logotype'>
              <img src={logo} width={200} height={48} alt='CF IT-SPROUT' />
            </a>
          </h1>
          <div className={opened ? 'burger' : 'burger open'} onClick={toggleMenu}>
            <span className='tt'></span>
            <span className='mm'></span>
            <span className='bb'></span>
          </div>
          <div className={opened ? 'menu active' : 'menu'}>
            <a href='#about' className='mx-3 px-1' onClick={toggleMenu}>
              About Us
            </a>
            <a href='#results' className='mx-3 px-1' onClick={toggleMenu}>
              Our results
            </a>
            <a href='#donations' className='mx-3 px-1' onClick={toggleMenu}>
              Donation details
            </a>
            <a href='#contact' className='mx-3 px-1' onClick={toggleMenu}>
              Contact
            </a>
          </div>
          <a href='#donations' className='button outline mob-button'>SWIFT</a>
        </nav>
      </div>
    </header>
  )
}

export { Nav }
