import React from 'react'
import { Nav } from '../components/Nav'
import { Intro } from './parts/Intro'
import { About } from './parts/About'
import { Results } from './parts/Results'
import { Donations } from './parts/Donations'
import { Footer } from '../components/Footer'
import { PopUp } from './parts/PopUp'

const Home = () => {

  return (
    <>
      <PopUp />
      <Nav />
      <Intro />
      <About />
      <Results />
      <Donations />
      <Footer />
    </>
  )
}

export { Home }
