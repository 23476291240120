import { React, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import TagManager from 'react-gtm-module'
import { Home } from './pages/Home';
import { Privacy } from './pages/Privacy';
import { Cookie } from './pages/Cookie';
import { Thanks } from './pages/Thanks';
import { NotFound } from './pages/NotFound';

import './styles/custom.sass';

function App() {

  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-PPS98NWB'
    })
  }, [])

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='privacy' element={<Privacy />} />
      <Route path='cookie' element={<Cookie />} />
      <Route path='thank-you' element={<Thanks />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

export { App };
