import React from 'react';
import pic from '../../assets/intro.webp';

const Intro = () => {
  const handleButtonClick = () => {
    console.log('button');
  };

  return (
    <section id='top' className='h-80 flex jcc aic'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-7 flex aic'>
            <div className='my-3'>
              <h2>Our recent aim is...</h2>
              <div className='s-10'></div>
              <h1 className='c-dark title-intro'>
                We help restore the quality of life for disabled veterans
              </h1>
              <div className='s-20'></div>
              <a className='button outline' href='#donations'>
                SWIFT
              </a>
              <div className='m-30'></div>
              <a
                href='https://secure.wayforpay.com/donate/itsprout'
                className='button outline int'
                onClick={handleButtonClick}
                target='blank'
              >
                Quick donation
              </a>
            </div>
          </div>
          <div className='col-lg-5 flex aic my-3'>
            <img src={pic} alt={'veteran'} width={460} height={316} />
          </div>
        </div>
      </div>
    </section>
  );
};

export { Intro };
